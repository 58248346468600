* {
  box-sizing: border-box;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

button {
  margin: 10px 0;
  padding: 10px 20px;
  border: 1px solid black;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  display: block;
  width: 100%;
}

button:hover {
  background-color: #f0f0f0;
}

button:active {
  transform: scale(1.05);
}

input[type="email"] {
  width: 100%;
  padding: 10px;
}

.container {
  max-width: 500px;
  width: 100%;
}

.navigation-icons {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  fill: #888;
  cursor: pointer;
  margin-top: 1rem;
}

.navigation-icon {
  height: 100%;
}

.right-arrow {
  margin-left: auto;
}
